import {ACTION_TYPES} from "../constants"
import {useSelector} from "react-redux";
import {fetchAllAreass, getDriverss, getAllPackages, getExpenses, fetchMonthlyData} from '../api';

export const closeAlert = () => (dispatch) => {
    dispatch({ type: ACTION_TYPES.CLOSE_ALERT });
};

export const createAlert = ({ message, type, duration = 5000, extra = {} }) => (dispatch) => {
    dispatch({
        type: ACTION_TYPES.CREATE_ALERT,
        payload: message,
        typ: type,
        extra,
    });
    setTimeout(() => {
    dispatch({ type: ACTION_TYPES.CLOSE_ALERT });
    }, duration);
};


export const fetchAllData =()=>async(dispatch, getState)=>{
    try{
        dispatch({type:ACTION_TYPES.FETCH_ALL_DATA_REQUEST});      
        const {data} =    await fetchAllAreass();
        const {data:data1} = await getDriverss();
        const {data:data2} = await getAllPackages();
        const {data:data3} = (getState()?.user?.user?.role === 3) ? {} :await getExpenses()
        dispatch({type: ACTION_TYPES.FETCH_ALL_DATA_SUCCESS, payload:{area:data.data.count, drivers:data1.data.count, packages:data2.data.count, expenses:data3.data.count} })
    }
    catch(error){
        console.log(error.message)
        dispatch({type:ACTION_TYPES.FETCH_ALL_DATA_FAILED})
    }


}

export const getMonthlyData =(res = {})=>async(dispatch, getState)=>{
    try{
        dispatch({type:ACTION_TYPES.FETCH_ALL_DATA_REQUEST});      
        const {data} =    await fetchMonthlyData({...res});
        dispatch({type: ACTION_TYPES.FETCH_ALL_DATA_SUCCESS, payload:data.data})
    }
    catch(error){
        console.log(error.message)
        dispatch({type:ACTION_TYPES.FETCH_ALL_DATA_FAILED})
    }


}