import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { formatNumber } from "../../helpers/formatNumber";
const counts = [0,1,3,5,7,9,11,12,14,17]

const styles = {borderRight:"1px solid", fontSize: "13px"}

const TableIncome = ()=>{
    let {driverPackageIncome=[],lastState} = useSelector(({reports})=>reports);
    let {options} = useSelector(({area})=>area)
    const areaName = useMemo(()=>{
        if(lastState){
            return options.filter((it)=>it.id==lastState.areaId)
        } else {
            return options[0]
        }
    },[driverPackageIncome])
  
    return (
        <div style={{width: "100%"}}>

            <div className= "fw-bold border-bottom-0 bg-light" style={{padding: "10px",width: "100%",fontSize: "20px"}}>{areaName[0]?.areaName}</div>  
            <div className="table-responsive">
            <table
                className=" table table-hover table-row-dashed table-row-gray-300 align-middle listab"
                id="lastab"
                style={{ overflow: "scroll",padding: "10px"}}
                width="100%"
                cellSpacing={1}
                cellPadding={0}
                border={1}
            >
                <thead>
                <tr className="fw-bold border-bottom-0" style={{ backgroundColor: "black", color:"#fff"}}>
                    <td rowSpan={2} width="6%" className="min-w-100px" style={{...styles, borderBottom: "1px solid gray"}} >
                        <div style={{textAlign: "center", marginTop:"-88px"}}>
                        Date
                        </div>
                    </td>
                    <td colSpan={2} width="6%" className="min-w-100px" style={styles}>
                    Package A
                    </td>
                    <td colSpan={2} width="6%" className="min-w-100px" style={styles}>
                    Package B
                    </td>
                    <td colSpan={2} width="6%" className="min-w-100px" style={styles}>
                    Package D
                    </td>
                    <td colSpan={2} width="6%" className="min-w-100px" style={styles}>
                    Package E
                    </td>
                    <td colSpan={2} width="6%" className="min-w-100px" style={styles}>
                    Package F
                    </td>
                    <td rowSpan={2} style={{...styles, borderBottom: "1px solid gray", }} >Total Count</td>
                    <td colSpan={2} width="6%" className="min-w-100px" style={styles}>
                    Package G
                    </td>
                    <td colSpan={2} width="6%" className="min-w-100px" style={styles}>
                    Package H
                    </td>
                    <td rowSpan={2} style={{...styles,borderBottom: "1px solid gray"}} border={1}>Total Amount</td>
                   <td rowSpan={2} style={{...styles,borderBottom: "1px solid gray", paddingRight:"5px"}} border={1}>Hours Worked</td>     
                </tr>
                <tr style={{backgroundColor:"black", color:"#fff"}}>
                    {[0,0,0,0,0,0,0].map((it,i)=>(
                        <>
                        <td style={{border: '1px solid #fff',fontSize: "14px"}}>Count</td>
                        <td style={{border: '1px solid #fff',fontSize: "14px"}}>Driver Pay</td>
                        </>
                    ))}
                </tr>
                </thead>
                <tbody>
                {driverPackageIncome?.length ? driverPackageIncome.map((row,i)=> 
                i!==driverPackageIncome?.length-1 ?
                <tr key={i} style={{fontSize: "13px"}}>
                <td style={{padding: "5px"}} width={6}>
                    {`${new Date(row[0]?.date).getDate()}/${new Date(row[0]?.date).getMonth()+1}/${new Date(row[0]?.date).getFullYear()}`}
                    </td>
                    <td style={{fontSize:"13px"}} width={6}>{row[0]?.count}</td>
                    <td style={{fontSize:"13px"}} width={6}>${formatNumber(row[0]?.driverPay || 0)}</td>
                    <td style={{fontSize:"13px"}} width={6}>{row[1]?.count}</td>
                    <td style={{fontSize:"13px"}} width={6}>${formatNumber(row[1]?.driverPay || 0)}</td>
                    <td style={{fontSize:"13px"}} width={6}>{row[2]?.count}</td>
                    <td style={{fontSize:"13px"}} width={6}>${formatNumber(row[2]?.driverPay || 0)}</td>
                    <td style={{fontSize:"13px"}} width={6}>{row[3]?.count}</td>
                    <td style={{fontSize:"13px"}} width={6}>${formatNumber(row[3]?.driverPay || 0)}</td>
                    <td style={{fontSize:"13px"}} width={6}>{row[4]?.count}</td>
                    <td style={{fontSize:"13px"}} width={6}>${formatNumber(row[4]?.driverPay || 0)}</td>
                    <td style={{fontSize:"13px"}} width={6}>{row[4]?.horizontalAFCountTotal}</td>
                    <td style={{fontSize:"13px"}} width={6}>{row[5]?.count}</td>
                    <td style={{fontSize:"13px"}} width={6}>${formatNumber(row[5]?.driverPay || 0)}</td>
                    <td style={{fontSize:"13px"}} width={6}>{row[6]?.count}</td>
                    <td style={{fontSize:"13px"}} width={6}>${formatNumber(row[6]?.driverPay || 0)}</td>
                    <td style={{fontSize:"13px"}} width={6}>${formatNumber(row[6]?.horizontalGrandTotal || 0)}</td>
                    <td style={{fontSize:"13px"}} width={6}>{formatNumber(row[0]?.totalWorkingHours || 0)}</td>
                </tr>
                :null
                ):null}
                </tbody>
                <tfoot>
                    <tr>
                    {driverPackageIncome?.length ? driverPackageIncome[driverPackageIncome?.length-1].map((col,i)=>
                    <td key={i} colSpan={1} width="6%"  style={{fontSize:"13px"}}>
                        {counts.includes(i) ? Number.isFinite(col) ? col: col :`$${formatNumber(col)}`}
                    </td>
                    ):null}
                    </tr>
                </tfoot>
            </table>
            </div>
        </div>
    )
}
export default TableIncome;