import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Selector, ViewDatePicker } from "../../../components/common";
import { useDispatch, useSelector } from "react-redux";
import { fetchDrivers } from "../../../actions";
import InputSelect from "../../../components/common/InputSelect";
import { NotificationManager } from "react-notifications";
import { getDriverPackageInvoices } from "../../../actions/driverPackageInvoices";

const invoiceStatusType = ["new", "paid", "unpaid", "partial", "void"];

const DriverPackageIncome = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(1);
  const location = useLocation();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  const initialValues = {
    driver: null,
    status: "",
    start_date: "",
    end_date: "",
  };

  const [state, setState] = useState(initialValues)
  let { allDrivers = [] } = useSelector(({ driver }) => driver);

  const onkeyChange = (key, value) => {
    setState({ ...state, [key]: value });
  };


  const handleSubmit = (e) => {
    if(!state.start_date && state.end_date){
      NotificationManager.error("Start date required");
      return
    }
    if(!state.end_date && state.start_date){
      NotificationManager.error("End date required");
      return
    }
    
    let payload = {};
    if (state.start_date) payload["start_date"] = state.start_date;
    if (state.end_date) payload["end_date"] = state.end_date;
    if (state.driver) payload["driver_id"] = +state.driver;
    payload["status"] = state.status;
    dispatch(getDriverPackageInvoices(payload));
  };


  const handleShowAll = () => {
    setOpen(false);
    setState(initialValues);
    dispatch(getDriverPackageInvoices());
  };

  useEffect(() => {
    if(active === 1){
      dispatch(getDriverPackageInvoices())
    }
    return () => setOpen(false);
  }, [active]);

  useEffect(() => {
    dispatch(fetchDrivers());
  }, []);

  return (
    <div className="col-xl-8 mb-5 mb-xl-10" style={{ width: "100%" }}>
      <div className="card card-flush h-md-100">
        <div className="card-body px-5">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ul className="nav nav-pills nav-pills-custom mb-3">
              {/*begin::Item*/}
              <li className="nav-item mb-3 me-3 me-lg-6">
                <NavLink
                  to="list"
                  className="nav-text text-gray-800 fw-bold fs-6 lh-1"
                  style={({ isActive }) => (isActive ? setActive(1) : {})}
                >
                  <div
                    className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-120px h-85px pt-5 pb-2 active"
                    onClick={() => setOpen(false)}
                    data-bs-toggle="pill"
                  >
                    <div className="nav-icon mb-3">
                      <i className="bi bi-receipt fs-2" />
                    </div>
                    <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">
                       Driver / Sorter Invoices
                    </span>
                    <span
                      className={
                        active === 1
                          ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"
                          : ""
                      }
                    />
                  </div>
                </NavLink>
              </li>
              <li className="nav-item mb-3 me-3 me-lg-6">
                <NavLink
                  to="create"
                  className="nav-text text-gray-800 fw-bold fs-6 lh-1"
                  style={({ isActive }) => (isActive ? setActive(2) : {})}
                >
                  <div
                    className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden w-80px h-85px pt-5 pb-2 active"
                    data-bs-toggle="pill"
                  >
                    <div className="nav-icon mb-3">
                      <i className="bi bi-plus-lg" />
                    </div>
                    <span className="nav-text text-gray-800 fw-bold fs-6 lh-1">
                      Create
                    </span>
                    <span
                      className={
                        active === 2
                          ? "bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"
                          : ""
                      }
                    />
                  </div>
                </NavLink>
              </li>
            </ul>
            {active === 1 && pathname === "/reports/driverPackageInvoice/list" && (
              <ul className="nav nav-pills nav-pills-custom mb-3">
                <li className="nav-item mb-3 me-3 me-lg-6">
                  <button
                    style={{ float: "left", marginLeft: 10 }}
                    type="submit"
                    name="description"
                    className="btn btn-primary"
                    id="kt_account_profile_details_submit"
                    onClick={handleShowAll}
                  >
                    {" "}
                    Show All
                  </button>
                </li>
                <li className="nav-item mb-3 me-3 me-lg-6">
                  {location.pathname !== "/drivers/customRate" && (
                    <button
                      type="submit"
                      style={{ float: "right", marginleft: 20 }}
                      name="description"
                      className="btn btn-primary"
                      id="kt_account_profile_details_submit"
                      onClick={() => setOpen(!open)}
                    >
                      {" "}
                      <i class="bi bi-search"></i> Search
                    </button>
                  )}
                </li>
              </ul>
            )}
          </div>

          {open && active === 1 && (
            <div style={{ backgroundColor: "#fff", margin: "10px 0" }}>
              <div
                id="kt_app_content"
                className="app-content flex-column-fluid"
              >
                <div
                  id="kt_app_content_container"
                  className="app-container container-xxl"
                >
                  <div className="card mb-5 mb-xl-10">
                    <div
                      id="kt_account_settings_profile_details"
                      className="collapse show"
                    >
                      <form
                        id="kt_account_profile_details_form"
                        className="form"
                      >
                        <div className="card-body border-top p-9">
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                              Start Date
                            </label>
                            <div className="col-lg-8">
                              <ViewDatePicker
                                name="date"
                                className="form-control form-control-lg form-control-solid"
                                placeholder="Date"
                                onChange={(value) =>
                                  onkeyChange("start_date", value)
                                }
                                value={state.start_date}
                                style={{ paddingLeft: 10 }}
                              />
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                              End Date
                            </label>
                            <div className="col-lg-8">
                              <ViewDatePicker
                                name="date"
                                className="form-control form-control-lg form-control-solid"
                                placeholder="Date"
                                onChange={(value) =>
                                  onkeyChange("end_date", value)
                                }
                                value={state.end_date}
                                style={{ paddingLeft: 10 }}
                              />
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                              Driver/Sorter
                            </label>
                            <div
                              className="col-lg-8 fv-row"
                              style={{ position: "relative" }}
                            >
                              <InputSelect
                                data={
                                  allDrivers?.length
                                    ? allDrivers.map((it) => {
                                        return {
                                          value: it.id,
                                          title: `${it?.firstName} ${it?.lastName}`,
                                          status: it.status,
                                        };
                                      })
                                    : null
                                }
                                onChange={(value) =>
                                  onkeyChange("driver", value)
                                }
                                value={state.driver}
                              />
                            </div>
                          </div>
                          <div className="row mb-6">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                              Status
                            </label>
                            <div className="col-lg-8 fv-row">
                              <Selector
                                data={invoiceStatusType.map((it) => {
                                  return {
                                    value: it,
                                    title: it,
                                  };
                                })}
                                onChange={(value) =>
                                  onkeyChange("status", value)
                                }
                                value={state.status}
                              />
                            </div>
                            {/*end::Col*/}
                          </div>
                        </div>
                        <div
                          className="card-footer d-flex "
                          style={{
                            border: "none",
                            marginLeft: 0,
                            marginTop: -30,
                          }}
                        >
                          <button
                            type="button"
                            name="description"
                            className="btn btn-primary"
                            id="kt_account_profile_details_submit"
                            onClick={handleSubmit}
                          >
                            Find
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Outlet context={[setOpen]} />
        </div>
      </div>
    </div>
  );
};

export default DriverPackageIncome;

const styles = {
  backgroundColor: "#f5f8fa",
  width: "100%",
  height: "100%",
  padding: "10px 20px",
  border: "none",
  outline: "none",
  borderRadius: "20px",
};
