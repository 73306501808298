import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import DriverPackageInvoiceTable from "./driverPackageInvoiceTable";
import html2canvas from "html2canvas";
import  { jsPDF } from "jspdf";
import { formatNumber } from "../../../helpers/formatNumber";

const styles = {
  padding: "1em",
  backgroundColor: "#fff",
};
// function formatInvoiceId(id) {
//   const idString = id?.toString();
//   const chunks = [];

//   for (let i = idString.length - 1; i >= 0; i -= 3) {
//     const chunk = idString.slice(Math.max(0, i - 2), i + 1);
//     chunks.unshift(chunk.padStart(3, "0"));
//   }
//   if (chunks.length === 1) {
//     return "000-" + chunks.join("-");
//   } else {
//     return chunks.join("-");
//   }
// }

const DownloadDriverPackageInvoice = () => {
const { driverPackageInvoice, loader } = useSelector(({ driverPackageInvoices }) => driverPackageInvoices);

const generatePDF = async() => {
  const reportElement = document.getElementById("report-screenshot");

  const canvas = await html2canvas(reportElement, {
    scale: 2,
    windowWidth: 1800,
    windowHeight: reportElement.scrollHeight
  })

  const imageData = canvas.toDataURL("image/png")

  const img = new Image();
  img.src = imageData;

  // Wait for the image to load
  img.onload = () => {
    const imgWidth = img.width;
    const imgHeight = img.height;

    // Create a new jsPDF instance with same width and height as the image
    const pdf = new jsPDF({
      orientation: imgWidth > imgHeight ? 'landscape' : 'portrait',
      unit: 'px',
      format: [imgWidth, imgHeight],
    });

    // Add the image to the PDF
    pdf.addImage(img, 'JPEG', 0, 0, imgWidth, imgHeight);

    // Save the PDF
    pdf.save(`${new Date().toISOString()}.pdf`);
  }
};

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          style={{ marginLeft: 10 }}
          className="btn btn-primary"
          onClick={generatePDF}
        >
          Download Pdf
        </button>
      </div>
      <div id="report-screenshot">
        <div id="kt_app_content" className="app-content flex-column-fluid py-20 px-5">
            <div style={{  width: "100%"}}>
                <div className="mx-auto w-100">
                <div className="d-flex justify-content-between flex-column flex-sm-row mb-19">
                   <div>
                     <h4 className=" text-gray-800 fs-2qx pe-5 pb-7">Tax Invoice#</h4>
                    <div className="fw-bold fs-2 " style={{paddingLeft:"5px"}}>
                        {driverPackageInvoice.driver
                        ? `${driverPackageInvoice.driver?.firstName} ${driverPackageInvoice.driver?.lastName}`
                        : null}
                        <br />
                        <span className="text-muted fs-5">
                        {`ABN ${driverPackageInvoice.driver?.abn || "N/A"}`}
                        </span>
                    </div>
                   </div>
                    <div className="text-sm-end">
                    <NavLink className="d-block mw-150px ms-sm-auto">
                        <img
                        alt="Logo"
                        src="/assets/media/images/img-logo.png"
                        className="w-100"
                        />
                    </NavLink>
                    <div className="text-sm-end fw-semibold fs-4 text-muted mt-7">
                        {/* <div>Address will be placed here</div> */}
                        <div> St. Mina Transport Pty Ltd</div>
                        <div>ABN 81 136 635 848</div>
                    </div>
                    </div>
                </div>

                <div className="pb-12">
                    <div className="d-flex flex-column gap-7 gap-md-10">
                    <div className="d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bold">
                        <div className="flex-root d-flex flex-column">
                        <span className="text-muted">Date created</span>
                        <span className="fs-5">
                            {new Date().toDateString()}
                        </span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between flex-column">
                        <hr />
                        <div
                        className="table-responsive border-bottom mb-9"
                        id="report-table"
                        >
                        <DriverPackageInvoiceTable/>
                        </div>
                    </div>
                    </div>
                </div>
                <div
                    style={{ display: "flex", justifyContent: "flex-end" }}
                >
                    <div className="table-responsive">
                    <div
                        className="tab"
                        style={{
                        border: "1px solid",
                        width: "300px",
                        borderRadius: "1em",
                        }}
                    >
                        <table
                        style={{
                            borderCollapse: "collapse",
                            width: "100%",
                            borderRadius: "1em",
                            overflow: "hidden",
                        }}
                        >
                        <tr>
                            <th
                            colspan="2"
                            style={{
                                backgroundColor: "black",
                                padding: "1em",
                                color: "#fff",
                                fontSize: "15px",
                            }}
                            >
                            Invoice Summary
                            </th>
                        </tr>
                        <tr>
                            <td
                            style={{
                                backgroundColor: "white",
                                padding: "0.5em",
                                fontSize: "15px",
                            }}
                            >
                            Total Hours Worked
                            </td>
                            <td
                            style={{
                                backgroundColor: "white",
                                padding: "0.5em",
                                fontSize: "15px",
                            }}
                            >
                            {formatNumber(driverPackageInvoice.hours_worked || 0)}
                            </td>
                        </tr>
                        <tr>
                            <td style={{
                                backgroundColor: "white",
                                padding: "0.5em",
                                fontSize: "15px",
                            }}>
                                Total
                            </td>
                            <td style={{
                                backgroundColor: "white",
                                padding: "0.5em",
                                fontSize: "15px",
                            }}>${formatNumber(driverPackageInvoice.total_amount || 0)}
                            </td>                       
                        </tr>
                        <tr>
                            <td style={{
                                backgroundColor: "white",
                                padding: "0.5em",
                                fontSize: "15px",
                            }}>
                                Total Amount (inc. GST)
                            </td>
                            <td style={{
                                backgroundColor: "white",
                                padding: "0.5em",
                                fontSize: "15px",
                            }}>
                                ${formatNumber(driverPackageInvoice.total_amount || 0)}
                            </td>                       
                        </tr>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadDriverPackageInvoice;
