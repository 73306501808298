import { ACTION_TYPES, expensesList } from "../constants";
import {
  postExpenses,
  getAllExpenses,
  getFilteredExpense,
  getExpense,
  updateExpense,
  deleteExpense,
  fetchAllExpenseSalary,
  deleteExpenseSalary,
  createExpenseSalary,
  updateExpenseSalaryapi,
} from "../api";
import { NotificationManager } from "react-notifications";

export const getExpenses = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.GET_EXPENSES_REQUEST });
    const {
      page = 1,
      pageSize = 20,
      order = "DESC",
      field = "createdAt",
    } = getState().expenses;
    const filter = { page, pageSize, order, field };
    const { data } = await getAllExpenses(filter);  
    if(data?.data?.rows?.length>0){
        let updateArray =  data?.data?.rows?.map((ele)=>(!!ele?.receiptDocuments?{...ele,receiptDocuments:ele.receiptDocuments.split(",")}:{...ele,receiptDocuments:[]}))
        data.data.rows = updateArray
    }
    dispatch({ type: ACTION_TYPES.GET_EXPENSES_SUCCESS, payload: data?.data });
  } catch (err) {
    dispatch({ type: ACTION_TYPES.GET_EXPENSES_FAILED });
    NotificationManager.error(
      err?.response?.data?.msg ??
        (err?.response?.data?.type || "Something went wrong")
    );
  }
};

export const filteredExpense = (res) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.GET_EXPENSES_REQUEST });
    const {
      page = 1,
      pageSize = 20,
      order = "ASC",
      field = "createdAt",
    } = getState().expenses;
    let filter = { page, pageSize: 20, order, field };
    const response = Object.keys(res);
    let temp;
    if (response?.length) {
      if (res.category) filter.category = res.category;
      if (res.status) filter.status = res.status;
      if (res.driver_id) filter.driver_id = res.driver_id;
      if (res.startDate) filter.startDate = res.startDate;
      if (res.endDate) filter.endDate = res.endDate;
      temp = await getFilteredExpense(filter);
    } else temp = await getAllExpenses(filter);
    const { data } = temp;
    dispatch({ type: ACTION_TYPES.GET_EXPENSES_SUCCESS, payload: data?.data });
  } catch (err) {
    dispatch({ type: ACTION_TYPES.GET_EXPENSES_FAILED });
    NotificationManager.error(
      err?.response?.data?.msg ??
        (err?.response?.data?.type || "Something went wrong")
    );
  }
};

export const addExpenses =
  (res = {}, navigate) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.EXPENSES_REQUEST });
      const { data } = await postExpenses(res);
      NotificationManager.success("Successfullly added Expenses");
      dispatch(getExpenses());
      navigate(-1);
    } catch (error) {
      dispatch({ type: ACTION_TYPES.EXPENSES_FAILED });
      NotificationManager.error(
        error?.response?.data?.msg ??
          (error?.response?.data?.type || "Something went wrong")
      );
    }
  };

export const getExpens = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.GET_EXPENSES_REQUEST });
    const { allExpenses } = getState().expenses;
    const { data } = await getExpense(id);
    dispatch({
      type: ACTION_TYPES.GET_EXPENSES_SUCCESS,
      payload: { ...allExpenses, count: 1, rows: [data?.data] },
    });
  } catch (err) {
    dispatch({ type: ACTION_TYPES.GET_EXPENSES_FAILED });
    NotificationManager.error(
      err?.response?.data?.msg ??
        (err?.response?.data?.type || "Something went wrong")
    );
  }
};

export const updateExpenses = (res, navigate) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.EDIT_EXPENSE_REQUEST });
    const { expense } = getState().expenses;
    const { data } = await updateExpense(expense.id, res);
    dispatch({ type: ACTION_TYPES.EDIT_EXPENSE_SUCCESS });
    NotificationManager.success("Successfullly updated Expenses");
    navigate(-1);
    dispatch(getExpenses());
  } catch (error) {
    dispatch({ type: ACTION_TYPES.EDIT_EXPENSE_FAILED });
    NotificationManager.error(
      error?.response?.data?.msg ??
        (error?.response?.data?.type || "Something went wrong")
    );
  }
};

export const setExpense = (res) => (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.EXPENSE_REQUEST });
    dispatch({ type: ACTION_TYPES.EXPENSE_SUCCESS, payload: res });
  } catch (error) {
    console.log(error.message);
    dispatch({ type: ACTION_TYPES.EXPENSE_FAILURE });
  }
};

export const deleteExpenses = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.EXPENSE_DELETE_REQUEST });
    const { data } = await deleteExpense(id);
    dispatch({ type: ACTION_TYPES.EXPENSE_DELETE_SUCCESS });
    NotificationManager.success("Successfully deleted expenses");
    dispatch(getExpenses());
  } catch (error) {
    dispatch({ type: ACTION_TYPES.EXPENSE_DELETE_FAILED });
    NotificationManager.error(
      error?.response?.data?.msg ??
        (error?.response?.data?.type || "Something went wrong")
    );
  }
};

export const getSalaryExpenses = (filter) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.SALARY_EXPENSE_REQUEST });
    const { data } = await fetchAllExpenseSalary(filter);
    dispatch({
      type: ACTION_TYPES.SALARY_EXPENSE_SUCCESS,
      payload: data?.data,
    });
  } catch (err) {
    dispatch({ type: ACTION_TYPES.SALARY_EXPENSE_FAILED });
    NotificationManager.error(
      err?.response?.data?.msg ??
        (err?.response?.data?.type || "Something went wrong")
    );
  }
};

export const setExpenseSalaryExpenses = (res) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.SET_SALARY_EXPENSE_REQUEST });
    dispatch({ type: ACTION_TYPES.SET_SALARY_EXPENSE_SUCCESS, payload: res });
  } catch (err) {
    dispatch({ type: ACTION_TYPES.SET_SALARY_EXPENSE_FAILED });
    NotificationManager.error(
      err?.response?.data?.msg ??
        (err?.response?.data?.type || "Something went wrong")
    );
  }
};

export const deleteSalaryExpenses = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTION_TYPES.DELETE_SALARY_EXPENSE_REQUEST });
    await deleteExpenseSalary(id);
    dispatch({ type: ACTION_TYPES.DELETE_SALARY_EXPENSE_REQUEST });
    NotificationManager.success("Successfully deleted");
    dispatch(getSalaryExpenses());
  } catch (error) {
    dispatch({ type: ACTION_TYPES.DELETE_SALARY_EXPENSE_REQUEST });
    NotificationManager.error(
      error?.response?.data?.msg ??
        (error?.response?.data?.type || "Something went wrong")
    );
  }
};

export const addExpenseSalary =
  (res = {}, navigate) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.CREATE_SALARY_EXPENSE_REQUEST });
      await createExpenseSalary(res);
      NotificationManager.success("Successfullly added Expenses");
      // dispatch(getSalaryExpenses())
      navigate("/expenses/salary");
    } catch (error) {
      dispatch({ type: ACTION_TYPES.CREATE_SALARY_EXPENSE_FAILED });
      NotificationManager.error(
        error?.response?.data?.msg ??
          (error?.response?.data?.type || "Something went wrong")
      );
    }
  };

export const updateExpenseSalary =
  (res = {}, navigate) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ACTION_TYPES.CREATE_SALARY_EXPENSE_REQUEST });
      await updateExpenseSalaryapi(res.id, res.data);
      NotificationManager.success("Successfullly updated Salary Expenses");
      // dispatch(getSalaryExpenses())
      navigate("/expenses/salary");
    } catch (error) {
      dispatch({ type: ACTION_TYPES.CREATE_SALARY_EXPENSE_FAILED });
      NotificationManager.error(
        error?.response?.data?.msg ??
          (error?.response?.data?.type || "Something went wrong")
      );
    }
  };
